@import url("https://use.typekit.net/pwp0zcl.css");

:root {
  --background-color: white;
  --button-text-color: #3d828a;
  --color: #526972;
  --soft-blue: rgb(130, 130, 214);
  --off-white: rgb(212, 212, 211);
  --yardy-purple: #8a5da8;
  --yardy-blue: rgb(129, 242, 239);
  --red: rgb(238, 33, 64);
  --sauce-red: rgb(241, 101, 101);
  --grey: #d2d1d1;
  --lime-green: #86ff86;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #231f20;
  color: var(--color);
  font-family: Komet;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hole {
  flex: 0 0 200px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(black, white);
  margin: 80px auto;
}

label {
  font-size: 3rem;
}

input {
  border-radius: 10px;
  padding: 5px;
}

button {
  background: white;
  font-size: 2rem;
  border-radius: 40px;
  padding: 4px 20px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* input {
  height: 20px;
  width: 70%;
  margin: 10px 0px;
  border: 2px solid #526972;
  background: var(--background-color);
  font-size: 18px;
  padding: 5px;
  border-radius: 10px;
} */

/* button {
  background: var(--background-color);
  color: var(--button-text-color);
  border: 3px solid #526972;
  font-size: 24px;
  padding: 9px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 500ms;
} */
@media (hover: hover) {
  button:hover {
    background-color: var(--soft-blue);
  }
}
input,
textarea {
  color: white;
  border: 2px solid #526972;
  width: 100%;
  font-size: 24px;
}

a {
  color: rgb(130, 130, 214);
  text-decoration: underline;
  font-size: 24px;
  cursor: pointer;
}

ul {
  padding-inline-start: 20px;
}
li {
  padding: 3px;
}
ul > li:nth-child(odd) {
  font-size: 20px;
}
ul > li:nth-child(even) {
  margin-left: 20px;
}

input[type="checkbox"]::before {
  border: 1px solid #808080;
  content: "";
  background: #fff;
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
}

input[type="checkbox"]::after {
  position: relative;
  display: block;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: #b3b3b3 #dcddde #dcddde #b3b3b3;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

button:disabled {
  background: lightgrey;
  color: grey;
}
