.sauce {
  margin-top: 20px;
}

.title {
  font-size: 3rem;
  color: white;
  font-weight: 700;
  /* text-shadow: rgb(255, 255, 255) 3px 3px; */
  text-align: center;
}

.container {
  padding: 18px;
  max-width: 500px;
  margin: auto;
}

.form > input {
  margin-bottom: 10px;
}

label {
  color: white;
  font-size: 1.5rem;
  /* text-shadow: rgb(255, 255, 255) 1px 1px; */
}

input {
  /* font-family: Shlop; */
  font-size: 1.5rem;
  color: black;
}

button {
  /* background-color: var(--sauce-red); */
  color: black;
  font-weight: 600;
  font-size: 3rem;
  padding: 10px 30px;
  margin: 50px auto 0px;
  display: block;
}

button.bottom {
  position: absolute;
  bottom: 50px;
  width: 200px;
  left: 50%;
  margin-left: -100px;
}

.details {
  font-size: 2.5rem;
  color: white;
  text-align: center;
}
.md {
  font-size: 1.5rem;
}
a.details {
  color: rgb(131, 131, 255);
}
