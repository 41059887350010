@import url(https://use.typekit.net/pwp0zcl.css);
:root {
  --background-color: white;
  --button-text-color: #3d828a;
  --color: #526972;
  --soft-blue: rgb(130, 130, 214);
  --off-white: rgb(212, 212, 211);
  --yardy-purple: #8a5da8;
  --yardy-blue: rgb(129, 242, 239);
  --red: rgb(238, 33, 64);
  --sauce-red: rgb(241, 101, 101);
  --grey: #d2d1d1;
  --lime-green: #86ff86;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #231f20;
  color: #526972;
  color: var(--color);
  font-family: Komet;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hole {
  flex: 0 0 200px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(black, white);
  margin: 80px auto;
}

label {
  font-size: 3rem;
}

input {
  border-radius: 10px;
  padding: 5px;
}

button {
  background: white;
  font-size: 2rem;
  border-radius: 40px;
  padding: 4px 20px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* input {
  height: 20px;
  width: 70%;
  margin: 10px 0px;
  border: 2px solid #526972;
  background: var(--background-color);
  font-size: 18px;
  padding: 5px;
  border-radius: 10px;
} */

/* button {
  background: var(--background-color);
  color: var(--button-text-color);
  border: 3px solid #526972;
  font-size: 24px;
  padding: 9px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 500ms;
} */
@media (hover: hover) {
  button:hover {
    background-color: rgb(130, 130, 214);
    background-color: var(--soft-blue);
  }
}
input,
textarea {
  color: white;
  border: 2px solid #526972;
  width: 100%;
  font-size: 24px;
}

a {
  color: rgb(130, 130, 214);
  text-decoration: underline;
  font-size: 24px;
  cursor: pointer;
}

ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
li {
  padding: 3px;
}
ul > li:nth-child(odd) {
  font-size: 20px;
}
ul > li:nth-child(even) {
  margin-left: 20px;
}

input[type="checkbox"]::before {
  border: 1px solid #808080;
  content: "";
  background: #fff;
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
}

input[type="checkbox"]::after {
  position: relative;
  display: block;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: #b3b3b3 #dcddde #dcddde #b3b3b3;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

button:disabled {
  background: lightgrey;
  color: grey;
}

.App {
  max-width: 800px;
  display: block;
  margin: auto;
  /* padding: 30px 10px; */
}

.active {
  background-color: aqua;
}

.responses__confirm__container {
  margin-top: 20px !important;
  width: 90%;
  display: block;
  margin: auto;
}

.status__nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

.status__line {
  margin: 10px;
}

.status__line > div:first-child {
  font-size: 3rem;
  margin-bottom: 15px;
}

.description {
  color: var(--off-white);
  padding: 15px;
  max-width: 800px;
}

.input--borderless {
  border: none;
}

.input__wrapper {
  margin: 20px 0px;
}

.input__label {
  font-size: 20px;
}

.button-wrapper {
  width: 100%;
  margin: 10px auto;
  cursor: pointer;
}

.button-wrapper:hover * {
  stroke: var(--soft-blue);
}

.button__wrapper > button:nth-child(2) {
  margin-left: 30px;
}

.textarea__container {
  height: 100%;
}

textarea {
  background: var(--background-color);
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}

.sending {
  animation: pulse 500ms infinite alternate;
}

@keyframes pulse {
  0% {
    background: rgb(142, 221, 14);
  }
  100% {
    background: var(--background-color);
  }
}

.leave-number__field {
  font-size: 42px;
  color: #1defc9;
  height: 50px;
  width: 90%;
  display: block;
  margin: auto;
  background: #dbaaff;
  padding: 10px;
}

button.leave-number {
  background: var(--yardy-blue);
  color: var(--yardy-purple);
  border: 3px solid #526972;
  font-size: 24px;
  padding: 10px 30px;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 500ms;
  font-weight: bold;
}

.sauce {
  margin-top: 20px;
}

.title {
  font-size: 3rem;
  color: white;
  font-weight: 700;
  /* text-shadow: rgb(255, 255, 255) 3px 3px; */
  text-align: center;
}

.container {
  padding: 18px;
  max-width: 500px;
  margin: auto;
}

.form > input {
  margin-bottom: 10px;
}

label {
  color: white;
  font-size: 1.5rem;
  /* text-shadow: rgb(255, 255, 255) 1px 1px; */
}

input {
  /* font-family: Shlop; */
  font-size: 1.5rem;
  color: black;
}

button {
  /* background-color: var(--sauce-red); */
  color: black;
  font-weight: 600;
  font-size: 3rem;
  padding: 10px 30px;
  margin: 50px auto 0px;
  display: block;
}

button.bottom {
  position: absolute;
  bottom: 50px;
  width: 200px;
  left: 50%;
  margin-left: -100px;
}

.details {
  font-size: 2.5rem;
  color: white;
  text-align: center;
}
.md {
  font-size: 1.5rem;
}
a.details {
  color: rgb(131, 131, 255);
}

